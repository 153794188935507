import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ImageFixed from '../shared/images/fixed';

const Section = ({ children, className, inverted, centred, image, alt, position, style, ...restProps }) => (
  <section
    {...restProps}
    className={classnames(className || 'bg-white', position === 'right' && 'flex-row-reverse', {
      inverted,
      'section-image': image,
      'text-center': centred,
    })}
    style={style}
  >
    {image && (
      <div className="image">
        <ImageFixed src={image} alt={alt} style={{ width: '100%', height: '100%', display: 'block' }} />
      </div>
    )}
    <div className={classnames(image ? 'content' : 'container')}> {children} </div>
  </section>
);

Section.defaultProps = {
  position: 'left',
  bgcolor: null,
  children: null,
  image: null,
  alt: '',
  style: null,
};

Section.propTypes = {
  bgcolor: PropTypes.string,
  image: PropTypes.string,
  children: PropTypes.node,
  alt: PropTypes.string,
  style: PropTypes.shape({}),
  position: PropTypes.string,
};

export default Section;
