import React, { useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Carousel from 'react-slick';
import arrowLeft from '../../../images/arrow-left-single.png';
import arrowRight from '../../../images/arrow-right-single.png';
import { keyPressSpace } from '../../../helpers';

const Arrow = ({ direction, onClick }) => (
  <div
    role="button"
    tabIndex={0}
    aria-label="Change slide"
    onClick={onClick}
    onKeyDown={e => keyPressSpace(e, onClick)}
  >
    <img
      className={classnames('arrow desktop', direction === 'left' ? 'left' : 'right')}
      src={direction === 'left' ? arrowLeft : arrowRight}
      alt={`arrow ${direction}`}
    />
  </div>
);

const ArrowMobile = ({ direction, onClick }) => (
  <div
    role="button"
    tabIndex={0}
    aria-label="Change slide"
    onClick={onClick}
    onKeyDown={e => keyPressSpace(e, onClick)}
  >
    <img
      className={classnames('arrow slide', direction)}
      src={direction === 'left' ? arrowLeft : arrowRight}
      alt={`arrow ${direction}`}
    />
  </div>
);

const SliderSmall = props => {
  const [slideIndex, setSlideIndex] = useState(0);

  const options = {
    beforeChange: (current, next) => setSlideIndex(next),
    appendDots: dots => <ul style={{ bottom: 0 }}> {dots} </ul>,
    customPaging: i => <div className={classnames('dot', i === slideIndex && 'active')} />,
  };

  const slides = props.isMobile ? 1 : props.isTablet ? 2 : 3;

  return (
    <div className="relative">
      <div className="hide left"></div>
      <Carousel
        nextArrow={props.isMobile ? <ArrowMobile direction="right" /> : <Arrow direction="right" />}
        prevArrow={props.isMobile ? <ArrowMobile direction="left" /> : <Arrow direction="left" />}
        infinite
        autoplay
        autoplaySpeed="60"
        arrows
        slidesToShow={slides}
        draggable={false}
        {...options}
      >
        {props.sliderImages.map((image, index) => (
          <div key={index}>
            <img
              alt={image.position}
              title={image.name}
              src={image.image}
              style={{
                maxHeight: '260px',
                maxWidth: '260px',
                margin: '0 auto',
                border: '2px solid teal',
                borderRadius: '999px',
                boxShadow: '4px 4px 10px 0 rgba(1, 114, 118, 0.15)',
              }}
            />
            <div className="text-center">
              <div style={{ margin: '15px 0 5px 0' }}>
                {' '}
                <h1>{image.name}</h1>{' '}
              </div>
              <div style={{ margin: '5px 0' }}> {image.position} </div>
            </div>
          </div>
        ))}
      </Carousel>
      <div className="hide right"></div>
    </div>
  );
};

export default connect(state => ({
  isMobile: state.app.isMobile,
  isTablet: state.app.isTablet,
}))(SliderSmall);
