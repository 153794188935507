import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Section from './section';

const Heading = ({ children, className, ...restProps }) => (
  <Section className={classnames(className)} centred {...restProps}>
    <h2 className="heading uppercase">{children}</h2>
  </Section>
);

Heading.defaultProps = {
  className: 'bg-teal-darker',
  children: null,
};

Heading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Heading;
