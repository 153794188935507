import React from 'react';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

function renderImage(file, { alt, style }) {
  return <Img fixed={file.node.childImageSharp.fixed} style={style} alt={alt} />;
}

const ImageFixed = function({ src, ...restProps }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  fixed(quality: 100, width: 1200) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      `}
      render={({ images }) =>
        renderImage(
          images.edges.find(image => image.node.relativePath === src),
          restProps
        )
      }
    />
  );
};

export default ImageFixed;
